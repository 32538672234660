<template>
  <v-container fluid id="printBill">
    <confirm-dialog
      :openDialog="dialogVoucherDelete"
      :onClicked="deleteVoucherConfirm"
      :onClose="
        () => {
          dialogVoucherDelete = false;
        }
      "
    ></confirm-dialog>

    <confirm-dialog
      :openDialog="dialogLock"
      :onClicked="changeIsLock"
      :onClose="
        () => {
          dialogLock = false;
        }
      "
      :dialogTitle="$t('bills.lockStatus')"
      :dialogText="!bill.isLock ? $t('bills.billLock') : $t('bills.billunlock')"
    ></confirm-dialog>

    <v-dialog v-model="dialog" max-width="500px" persistent>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-toolbar color="primary darken-1" dark>
            <v-card-title>
              <span class="">
                {{ $t("add") + " " + $t("vouchers.voucher") }}</span
              >
            </v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.voucherAmount"
                    :rules="[
                      (value) => value >= 0 || $t('ThisFieldIsRequired'),
                    ]"
                    :label="$t('vouchers.voucherAmount')"
                    :max="remainingAmount"
                    :min="0"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.note"
                    :label="$t('notes')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="green darken-1"
              class="white--text"
              @click="save"
              :disabled="!valid"
              :min-width="100"
            >
              <v-icon>mdi-content-save-outline</v-icon>
              {{ $t("save") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="close">{{ $t("cancel") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <patient-detail
      id="patientDetail"
      :patientGuid="bill.patientGuid"
    ></patient-detail>

    <v-row>
      <v-col disabled cols="12" md="12">
        <v-card outlined class="mt-5">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" sm="12">
                {{ $t("bills.billCode") }} : {{ bill.billCode }}
              </v-col>
              <v-col>
                {{ $t("date") }} :
                {{ bill.billDate | moment("YYYY/MM/DD hh:mm A") }}
              </v-col>
              <v-col cols="auto">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary "
                      outlined
                      class=""
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="printItem(1, item)"
                      ><v-icon class=""> mdi-printer </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t("print") }}
                  </span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6" sm="6" xs="12">
        <v-card outlined>
          <v-card-text>
            <v-data-table
              :headers="headers1"
              :items="table"
              hide-default-header
              hide-default-footer
              item-class="cls"
            >
              <template v-slot:[`item.text`]="{ item }">
                {{ item.text }}
              </template>

              <template v-slot:[`item.value`]="{ item }">
                <v-row v-if="item.isDiscount" class="title">
                  <v-col v-if="!editDiscount" align-self="center">
                    {{ item.value | number("0,0") }}
                  </v-col>
                  <v-col v-else>
                    <v-text-field
                      dense
                      hide-details
                      v-model="discountTextField"
                      type="number"
                      class="title"
                      :max="remainingAmount"
                      :min="0"
                      :rules="[
                        (value) => value >= 0 || $t('ThisFieldIsRequired'),
                      ]"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="auto">
                    <v-tooltip top v-if="editDiscount">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          icon
                          @click="closeDiscount"
                        >
                          <v-icon color="red darken-1"> mdi-close </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{ $t("cancel") }}
                      </span>
                    </v-tooltip>

                    <v-tooltip top v-if="!bill.isLock">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          icon
                          @click="changeDiscount"
                        >
                          <v-icon :color="editDiscount ? 'success' : ''">
                            {{ editDiscount ? "mdi-check" : "  mdi-pencil" }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{ !editDiscount ? $t("edit") : $t("save") }}
                      </span>
                    </v-tooltip>
                  </v-col>
                </v-row>

                <span v-else class="title">
                  {{ item.value | number("0,0") }}
                </span>
              </template>
            </v-data-table>
            <v-divider />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-card outlined height="226">
          <v-card-title>
            {{ $t("bills.groupAmount") }}
          </v-card-title>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td v-if="isInRole('65')">
                      {{ $t("services.serviceGroupType.general") }}
                    </td>
                    <td v-if="isInRole('65')">
                      {{ sumGroup(0) | number("0,0") }}
                    </td>

                    <td v-if="isInRole('66')">
                      {{ $t("services.serviceGroupType.dental") }}
                    </td>
                    <td v-if="isInRole('66')">
                      {{ sumGroup(1) | number("0,0") }}
                    </td>
                  </tr>
                  <!-- <tr v-if="isInRole('66')">
                    <td>
                      {{ $t("services.serviceGroupType.dental") }}
                    </td>
                    <td>
                      {{ sumGroup(1) | number("0,0") }}
                    </td>
                  </tr> -->
                  <tr>
                    <td v-if="isInRole('67')">
                      {{ $t("services.serviceGroupType.operation") }}
                    </td>
                    <td v-if="isInRole('67')">
                      {{ sumGroup(2) | number("0,0") }}
                    </td>
                    <td v-if="isInRole('83')">
                      {{ $t("services.serviceGroupType.test") }}
                    </td>
                    <td v-if="isInRole('83')">
                      {{ sumGroup(3) | number("0,0") }}
                    </td>
                  </tr>
                  <tr></tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-4" id="payButton">
      <v-col>
        <v-tooltip top v-if="isInRole('58')">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="dialogOpen"
              :disabled="bill.isPaid"
              elevation="0"
              hide-details
              color="success darken-1 "
              block
            >
              {{ bill.isPaid ? $t("bills.billIsPaid") : $t("bills.payment") }}
              <v-icon class="mx-2">{{
                bill.isPaid ? "mdi-check-outline" : "mdi-checkbook"
              }}</v-icon>
            </v-btn>
          </template>
          <span>
            {{
              bill.isPaid ? $t("bills.billIsPaid") : $t("bills.payment")
            }}</span
          >
        </v-tooltip>
      </v-col>
      <v-col style="text-align: end">
        <v-tooltip top v-if="isInRole('27')">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="remainingAmount > 0"
              block
              v-bind="attrs"
              elevation="0"
              v-on="on"
              @click="dialogLockOpen"
              hide-details
              color="primary"
            >
              {{ $t("bills.lockStatus") }}
              <v-icon>{{
                !bill.isLock
                  ? "mdi-lock-open-variant-outline"
                  : "mdi-lock-outline"
              }}</v-icon>
            </v-btn>
          </template>
          <span>
            {{ $t(bill.isLock ? "bills.billunlock" : "bills.billIsOpen") }}
          </span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-card outlined class="v-card-profile pb-2 mb-4">
      <v-card-title> {{ $t("procedures.procedures") }} </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="bodies"
          class=""
          hide-default-footer
          width="100%"
        >
          <template v-slot:[`item.dateReg`]="{ item }">
            {{ item.dateReg | moment("YYYY/MM/DD hh:mm A") }}
          </template>
          <template v-slot:[`item.totalAmount`]="{ item }">
            {{ (item.amount * item.quantity) | number("0,0") }}
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            {{ item.amount | number("0,0") }}
          </template>

          <template v-slot:[`item.serviceGroup`]="{ item }">
            {{ getServiceGroup(item.serviceGroup) }}
          </template>

          <template slot="body.append">
            <tr
              class="blue--text text--darken"
              :class="
                $vuetify.breakpoint.xsOnly
                  ? ' v-data-table__mobile-table-row'
                  : ''
              "
            >
              <td class="d-none d-sm-flex">{{ $t("total") }}</td>
              <td :class="$vuetify.breakpoint.xsOnly ? 'd-none' : ''"></td>
              <td :class="$vuetify.breakpoint.xsOnly ? 'd-none' : ''"></td>
              <td :class="$vuetify.breakpoint.xsOnly ? 'd-none' : ''"></td>
              <td
                :class="
                  $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
                "
              >
                <span class="hidden-sm-and-up">
                  {{ $t("procedures.totalAmount") }}
                </span>
                {{ sumField("amount") | number("0,0") }}
              </td>
              <td class="d-none d-sm-flex">
                <!-- {{ sumField("quantity") }} -->
              </td>
              <td
                :class="
                  $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
                "
              >
                <span class="hidden-sm-and-up">
                  {{ $t("bills.totalAmountFooter") }}
                </span>
                {{ this.totalAmount | number("0,0") }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-card outlined class="v-card-profile pb-2">
      <v-card-title> {{ $t("bills.paidAmounts") }} </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headersVocher"
          :items="vouchers"
          class=""
          hide-default-footer
        >
          <template v-slot:[`item.actions`]="{ item }">
            <div class="text-end">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    rounded
                    v-bind="attrs"
                    v-on="on"
                    @click="printItem(2, item)"
                    ><v-icon class=""> mdi-printer </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t("print") }}
                </span>
              </v-tooltip>
              <v-tooltip
                top
                v-if="!item.isLocked && !bill.isLock && isInRole('60')"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="red darken-2"
                    icon
                    @click="deleteVoucher(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon> mdi-delete </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("delete") }}</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.voucherAmount`]="{ item }">
            {{ item.voucherAmount | number("0,0") }}
          </template>
          <template v-slot:[`item.dateReg`]="{ item }">
            {{ item.dateReg | moment("YYYY/MM/DD hh:mm A") }}
          </template>

          <template slot="body.append">
            <tr
              class="blue--text text--darken"
              :class="
                $vuetify.breakpoint.xsOnly
                  ? ' v-data-table__mobile-table-row'
                  : ''
              "
            >
              <td class="d-none d-sm-flex pa-3">{{ $t("total") }}</td>
              <td
                :class="
                  $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
                "
              >
                <span
                  :class="$vuetify.breakpoint.xsOnly ? '' : 'hidden-sm-and-up'"
                >
                  {{ $t("total") }}
                </span>
                {{ sumVoucher() | number("0,0") }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import PatientDetail from "@/components/PatientDetail.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

export default {
  components: { PatientDetail, ConfirmDialog },

  data() {
    return {
      guid: this.$route.params.guid,
      valid: true,
      dialog: false,
      dialogLock: false,
      dialogVoucherDelete: false,
      discount: 0,
      paid: 0,
      panel: [1],

      search: "",
      headers: [
        { text: this.$t("services.serviceName"), value: "serviceName" },
        { text: this.$t("services.serviceGroup"), value: "serviceGroup" },
        { text: this.$t("doctors.doctorName"), value: "doctor.doctorName" },
        { text: this.$t("date"), value: "dateReg" },
        { text: this.$t("procedures.amount"), value: "amount" },
        { text: this.$t("procedures.quantity"), value: "quantity" },
        { text: this.$t("procedures.totalAmount"), value: "totalAmount" },
      ],
      headers1: [
        { text: "", value: "text" },
        { text: "", value: "value" },
      ],
      headersVocher: [
        { text: this.$t("vouchers.voucherNumber"), value: "voucherNumber" },
        { text: this.$t("vouchers.voucherAmount"), value: "voucherAmount" },
        { text: this.$t("notes"), value: "note" },
        { text: this.$t("date"), value: "dateReg" },
        { text: this.$t("users.userName"), value: "user.userName" },
        { text: "", value: "actions" },
      ],
      editedItem: {
        voucherAmount: 0,
        note: "",
      },
      // filteredVoucherAmount: 0,
      defaultItem: {
        voucherAmount: 0,
        note: "",
      },
      rules: [
        (value) => !!value || this.$t("ThisFieldIsRequired"),
        //(value) => (value && value.length <= 5) || "Max 5 characters",
      ],
      patient: {},
      bill: {},
      bodies: [],
      vouchers: [],
      currentVoucher: {},
      init: false,
      editDiscount: false,
      discountTextField: 0,
    };
  },
  created() {
    this.refreshTable(0);
  },

  computed: {
    totalAmount() {
      if (this.bodies && this.bodies.length > 0)
        return this.bodies.reduce(
          (a, b) => a + (b["amount"] * b["quantity"] || 0),
          0
        );
      return 0;
    },
    remainingAmount() {
      return this.totalAmount - this.paid - this.discount;
    },
    table() {
      return [
        {
          text: this.$t("procedures.totalAmount"),
          value: this.totalAmount,
        },
        {
          text: this.$t("bills.discount"),
          value: this.discount,
          isDiscount: true,
        },
        {
          text: this.$t("bills.paid"),
          value: this.paid,
          cls: "green--text text--darken",
        },
        {
          text: this.$t("bills.remainingAmount"),
          value: this.remainingAmount,
          cls: "red--text text--darken",
        },
      ];
    },
  },
  watch: {
    // filteredVoucherAmount(val) {
    //   console.log(val);
    //   this.editedItem.voucherAmount = JSON.parse(JSON.stringify(val));
    //   this.filteredVoucherAmount = JSON.parse(
    //     JSON.stringify(this.$options.filters.number(val, "0,0"))
    //   );
    // },
  },
  methods: {
    getServiceGroup(serviceGroup) {
      switch (serviceGroup) {
        case 0:
          return this.$t("services.serviceGroupType.general");
        case 1:
          return this.$t("services.serviceGroupType.dental");
        case 2:
          return this.$t("services.serviceGroupType.operation");
        case 3:
          return this.$t("services.serviceGroupType.test");
        default:
          return "";
      }
    },
    dialogLockOpen() {
      // console.log(this.init);
      if (this.init) {
        this.dialogLock = true;
      }
    },

    changeIsLock() {
      this.$axios
        .get(`Bill/ChangeIsLock?guid=${this.bill.guid}`)
        .then((response) => {
          //    console.log(response.data.data);
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }

          this.refreshTable();
        })
        .catch((e) => {
          this.type = "error";
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));

          console.log(e);
        });
      this.dialogLock = false;
    },
    refreshTable() {
      this.$axios
        .get(`Bill/Get?guid=${this.$route.params.guid}`)
        .then((response) => {
          //    console.log(response.data.data);
          this.bill = response.data.data.bill;
          this.discount = response.data.data.bill.totalDiscount;
          this.paid = response.data.data.totalPaid;
          //this.patient = response.data.data.bill.patient;
          this.bodies = response.data.data.bodies;
          this.discountTextField = JSON.parse(JSON.stringify(this.discount));

          this.$axios
            .get(`Voucher/GetBySource?guid=${this.bill.guid}`)
            .then((response) => {
              this.vouchers = response.data.data;
              this.init = true;
              //console.log(this.vouchers);
            });
        });
    },

    sumField(key) {
      // sum data in give key (property)
      if (this.bodies)
        return this.bodies.reduce((a, b) => a + (b[key] || 0), 0);
      return 0;
    },
    deleteVoucher(item) {
      this.dialogVoucherDelete = true;
      this.currentVoucher = item;
    },
    deleteVoucherConfirm() {
      this.$axios
        .delete("Voucher/Delete?guid=" + this.currentVoucher.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }
          this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));

          this.refreshTable();
          console.log(e);
        });
      this.currentVoucher = {};
      this.dialogVoucherDelete = false;
    },
    sumVoucher() {
      // sum data in give key (property)
      if (this.vouchers)
        return this.vouchers.reduce((a, b) => a + (b["voucherAmount"] || 0), 0);
      return 0;
    },
    sumGroup(key) {
      // sum data in give key (property)
      if (this.bodies)
        return this.bodies
          .filter((f) => {
            return f.serviceGroup == key;
          })
          .reduce((a, b) => a + (b["amount"] * b["quantity"] || 0), 0);
      return 0;
    },

    dialogOpen() {
      this.closeDiscount();

      this.dialog = true;
      this.editedItem.voucherAmount = JSON.parse(
        JSON.stringify(this.remainingAmount)
      );
      //this.filteredVoucherAmount = this.editedItem.voucherAmount;
    },

    close() {
      this.dialog = false;
      // this.refreshTable();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    changeDiscount() {
      if (!this.editDiscount) {
        this.editDiscount = true;
        this.discountTextField = JSON.parse(JSON.stringify(this.discount));
      } else {
        //   console.log(this.discountTextField);
        //    console.log(this.discount);
        if (this.discountTextField > this.remainingAmount + this.discount) {
          this.$toast.error(this.$t("error.CannotPaidMoreThanRemainingAmount"));
          this.discountTextField = JSON.parse(
            JSON.stringify(this.remainingAmount + this.discount)
          );
        } else {
          this.save();
        }
      }
    },

    closeDiscount() {
      this.editDiscount = false;
      this.discountTextField = JSON.parse(JSON.stringify(this.discount));
    },

    printItem(type, item) {
      var url = "";

      if (type == 1) {
        url = "Bill/Print?guid=" + this.bill.guid;
      }
      if (type == 2) {
        url = "Voucher/Print?guid=" + item.guid;
      }

      let postConfig = {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
        responseType: "blob",
      };
      this.$axios
        .get(url, postConfig)
        .then((response) => {
          if (response.status == 200) {
            var file = new Blob([response.data], { type: "application/pdf" });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          } else {
            this.$toast.error("Some thing went wrong");
          }
        })
        .catch((e) => {
          this.$toast.error("Some thing went wrong");
          console.log(e);
        });
    },
    // printItem() {
    //   this.$axios({
    //     url: "Bill/Print?guid=" + this.bill.guid,
    //     method: "GET",
    //     responseType: "blob",
    //   }).then((response) => {
    //     var fileURL = window.URL.createObjectURL(new Blob([response.data]));
    //     var fileLink = document.createElement("a");
    //     fileLink.href = fileURL;
    //     fileLink.setAttribute("download", "file.pdf");
    //     document.body.appendChild(fileLink);
    //     fileLink.click();
    //   });
    // },

    // printVoucher(item) {
    //   this.$axios({
    //     url: "Voucher/Print?guid=" + item.guid,
    //     method: "GET",
    //     responseType: "blob",
    //   }).then((response) => {
    //     var fileURL = window.URL.createObjectURL(new Blob([response.data]));
    //     var fileLink = document.createElement("a");
    //     fileLink.href = fileURL;
    //     fileLink.setAttribute("download", "file.pdf");
    //     document.body.appendChild(fileLink);
    //     fileLink.click();
    //   });
    // },
    save() {
      //var val = this.$refs.form.validate();
      if (!this.$refs.form || this.$refs.form.validate()) {
        var data = {
          voucherAmount: this.editedItem.voucherAmount,
          note: this.editedItem.note,
          discount: this.discountTextField,
          sourceGuid: this.bill.guid,
        };
        //console.log(data);
        this.$axios
          .post("Voucher/AddByBill", data)
          .then((response) => {
            if (response.data.status == "Successful") {
              this.$toast(this.$t("operationAccomplishedSuccessfully"));
              this.closeDiscount();
              this.close();
            } else {
              this.$toast.error(this.$t("error." + response.data.message));
            }

            this.refreshTable();
          })
          .catch((e) => {
            this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));

            this.refreshTable();
            console.log(e);
          });
      }
    },
  },
};
</script>

<style>
.v-application .title {
  font-family: inherit !important;
}
@media print {
  @page {
    size: A4;
  }

  #patientDetail {
    display: none;
  }
  #payButton {
    display: none;
  }
  .v-navigation-drawer,
  .v-app-bar {
    display: none;
    position: absolute;
  }
  .v-main {
    padding: 0 !important;
    width: 100%;
    height: 100%;
  }
  #printBill {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 11111;
    background: white;
    height: auto;
  }
}
</style>
